export const environment = {
  production: false,
  local: false,
  url: 'https://www.dev.roundpier.com',
  endpoint: 'https://www.dev.roundpier.com',
  prefix: '/api/',
  socketEndpoint: 'https://www.dev.roundpier.com',
  socketPort: '3443',
  groupChatSocketHost: 'https://www.dev.roundpier.com',
  groupChatSocketPort: 4443,
  routePrefix: '/',
  fbqID: '',
  domain: 'roundpier.com',
  tokenName: 'token-dev',
  collegesURL: 'https://colleges.dev.roundpier.com',
  sentryDsn:
    'https://e9a944056ff24fbcbf437b124161ee1f@o974232.ingest.sentry.io/5965176',
  hj: null,
  auth: {
    appleClientID: 'com.roundpier.dev',
    googleClientID:
      '712347469417-vd0dc4mfvajamk7uhuskn9jl04klep99.apps.googleusercontent.com',
    redirectURL: 'https://www.dev.roundpier.com'
  },
  amplitude: null,
  appleStoreURL:
    'https://itunes.apple.com/us/app/roundpier/id1400305303?ls=1&mt=8',
  googlePlayURL:
    'https://play.google.com/store/apps/details?id=com.roundpier.roundpier'
};
