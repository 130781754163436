import { Directive, Input } from '@angular/core';

import { IEvent } from '../interfaces';

@Directive({
  selector: '[amplitude]'
})
export class NoopAmplitudeDirective {
  @Input('amplitude') private readonly _amplitude: IEvent;
}
